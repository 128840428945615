import React, { useEffect, useRef } from "react";
import "./customcursor.scss";

const CustomCursor = () => {
  const cursorSm = useRef(null);
  const cursorLg = useRef(null);
  const positionRef = useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1,
  });

  // Mouse move event listener
  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;

      positionRef.current.mouseX = clientX - cursorSm.current.clientWidth / 2;
      positionRef.current.mouseY = clientY - cursorSm.current.clientHeight / 2;

      // Adjust for cursorLg as well
      positionRef.current.mouseX = clientX - cursorLg.current.clientWidth / 2;
      positionRef.current.mouseY = clientY - cursorLg.current.clientHeight / 2;
    };

    document.addEventListener("mousemove", handleMouseMove);

    // Cleanup on unmount
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // Follow mouse logic
  useEffect(() => {
    const followMouse = () => {
      const {
        mouseX,
        mouseY,
        destinationX,
        destinationY,
        distanceX,
        distanceY,
      } = positionRef.current;

      if (!destinationX || !destinationY) {
        positionRef.current.destinationX = mouseX;
        positionRef.current.destinationY = mouseY;
      } else {
        positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
        positionRef.current.distanceY = (mouseY - destinationY) * 0.1;

        if (
          Math.abs(positionRef.current.distanceX) +
            Math.abs(positionRef.current.distanceY) <
          0.1
        ) {
          positionRef.current.destinationX = mouseX;
          positionRef.current.destinationY = mouseY;
        } else {
          positionRef.current.destinationX += distanceX;
          positionRef.current.destinationY += distanceY;
        }
      }

      if (cursorSm.current && cursorLg.current) {
        cursorSm.current.style.transform = `translate3d(${positionRef.current.destinationX}px, ${positionRef.current.destinationY}px, 0)`;
        cursorLg.current.style.transform = `translate3d(${positionRef.current.destinationX}px, ${positionRef.current.destinationY}px, 0)`;
      }

      positionRef.current.key = requestAnimationFrame(followMouse);
    };

    followMouse();

    // Cleanup animation frame on unmount
    return () => {
      cancelAnimationFrame(positionRef.current.key);
    };
  }, []);

  return (
    <>
      <div className="cs-cursor_lg" ref={cursorLg}></div>
      <div className="cs-cursor_sm" ref={cursorSm}></div>
    </>
  );
};

export default CustomCursor;
