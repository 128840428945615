import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Loader from "./components/Loader";

const Home = lazy(() => import("./components/Pages/Home"));
const AboutPage = lazy(() => import("./components/Pages/AboutPage"));
const BlogPage = lazy(() => import("./components/Pages/BlogPage"));
const BlogDetailsPage = lazy(() =>
  import("./components/Pages/BlogDetailsPage")
);
const ContactPage = lazy(() => import("./components/Pages/ContactPage"));
const ErrorPage = lazy(() => import("./components/Pages/ErrorPage"));
const PortfolioPage = lazy(() => import("./components/Pages/PortfolioPage"));
const PortfolioDetailsPage = lazy(() =>
  import("./components/Pages/PortfolioDetailsPage")
);
const ServicesPage = lazy(() => import("./components/Pages/ServicesPage"));
const ServiceDetailsPage = lazy(() =>
  import("./components/Pages/ServiceDetailsPage")
);
const TeamDetails = lazy(() => import("./components/Pages/TeamDetails"));
const Service = lazy(() => import("./components/Pages/Service"));
const CategoryPage = lazy(() => import("./components/Pages/CategoryPage"));

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="service" element={<ServicesPage />} />
          <Route path="service/:service" element={<ServiceDetailsPage />} />
          <Route path="service/:service/:serviceName" element={<Service />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="portfolio/:project" element={<PortfolioDetailsPage />} />
          <Route path="team/:teamDetails" element={<TeamDetails />} />
          {/* <Route path="blog" element={<BlogPage />} />
          <Route
            path="blog/category/:categoryName"
            element={<CategoryPage />}
          />
          <Route path="blog/:blogId" element={<BlogDetailsPage />} /> */}
          <Route path="contact" element={<ContactPage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
