import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import SocialWidget from "../Widget/SocialWidget";
import Newsletter from "../Widget/Newsletter";
import "./header.scss";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import Div from "../Div";

export default function Header({ variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  // const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });

    // document.documentElement.setAttribute("data-theme", theme);
  }, []);

  // const toggleTheme = () => {
  //   const newTheme = theme === "light" ? "dark" : "light";
  //   setTheme(newTheme);
  //   localStorage.setItem("theme", newTheme);
  // };

  return (
    <>
      <header
        className={`cs-site_header cs-style1 text-uppercase ${
          variant ? variant : ""
        } cs-sticky_header ${isSticky ? "cs-sticky_header_active" : ""}`}
      >
        <Div className="cs-main_header">
          <Div className="container">
            <Div className="cs-main_header_in">
              <Div className="cs-main_header_left">
                <Link className="cs-site_branding" to="/">
                  <img src="/images/logo.svg" alt="Logo" />
                </Link>
              </Div>
              <Div className="cs-main_header_center">
                <Div className="cs-nav cs-primary_font cs-medium">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? "block" : "none"}` }}
                  >
                    <li>
                      <NavLink to="/" onClick={() => setMobileToggle(false)}>
                        Home
                      </NavLink>
                      {/* <DropDown>
                        <ul>
                          <li>
                            <Link to="/" onClick={() => setMobileToggle(false)}>
                              Main Home
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="photography-agency"
                              onClick={() => setMobileToggle(false)}
                            >
                              Photography Agency
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="creative-portfolio"
                              onClick={() => setMobileToggle(false)}
                            >
                              Creative Portfolio
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="digital-agency"
                              onClick={() => setMobileToggle(false)}
                            >
                              Digital Agency
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="marketing-agency"
                              onClick={() => setMobileToggle(false)}
                            >
                              Marketing Agency
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="freelancer-agency"
                              onClick={() => setMobileToggle(false)}
                            >
                              Freelancer Agency
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="architecture-agency"
                              onClick={() => setMobileToggle(false)}
                            >
                              Architecture Agency
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="creative-solution"
                              onClick={() => setMobileToggle(false)}
                            >
                              Creative Solution
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="personal-portfolio"
                              onClick={() => setMobileToggle(false)}
                            >
                              Personal Portfolio
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="showcase-portfolio"
                              onClick={() => setMobileToggle(false)}
                            >
                              Showcase Portfolio
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="case-study-showcase"
                              onClick={() => setMobileToggle(false)}
                            >
                              Case Study Showcase
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="video-showcase"
                              onClick={() => setMobileToggle(false)}
                            >
                              Video Showcase
                            </Link>
                          </li>
                        </ul>
                      </DropDown> */}
                    </li>
                    <li>
                      <NavLink
                        to="about"
                        onClick={() => setMobileToggle(false)}
                      >
                        About
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="service"
                        onClick={() => setMobileToggle(false)}
                      >
                        Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="portfolio"
                        onClick={() => setMobileToggle(false)}
                      >
                        Portfolio
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href="https://thetechshine.com/blog/"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => setMobileToggle(false)}
                      >
                        Blog
                      </a>
                    </li>

                    <li>
                      <NavLink
                        to="contact"
                        onClick={() => setMobileToggle(false)}
                      >
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? "cs-munu_toggle cs-toggle_active"
                        : "cs-munu_toggle"
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Div>
              </Div>
              <Div className="cs-main_header_right">
                <Div className="cs-toolbox">
                  <span
                    className="cs-icon_btn"
                    onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
                  >
                    <span className="cs-icon_btn_in">
                      <span />
                      <span />
                      <span />
                      <span />
                    </span>
                  </span>
                </Div>

                {/* <button onClick={toggleTheme} className="theme-toggle-btn">
                  {theme === "light" ? "Dark Mode" : "Light Mode"}
                </button> */}
              </Div>
            </Div>
          </Div>
        </Div>
      </header>

      <Div
        className={
          sideHeaderToggle ? "cs-side_header active" : "cs-side_header"
        }
      >
        <button
          className="cs-close"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div
          className="cs-side_header_overlay"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div className="cs-side_header_in">
          <Link className="cs-site_branding" to="/">
            <img src="/images/footer_logo.svg" alt="Logo" />
          </Link>
          <Div className="cs-side_header_box">
            <h2 className="cs-side_header_heading">
              Do you have a project in your <br /> mind? Keep connect us.
            </h2>
          </Div>
          <Div className="cs-side_header_box">
            <ContactInfoWidget title="Contact Us" withIcon />
          </Div>
          {/* <Div className="cs-side_header_box">
            <Newsletter
              title="Subscribe"
              subtitle="Subscribe to our company activity updates and stay connected with us"
              placeholder="example@gmail.com"
            />
          </Div> */}
          <Div className="cs-side_header_box">{/* <SocialWidget /> */}</Div>
        </Div>
      </Div>
    </>
  );
}
